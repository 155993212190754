<template>
  <div class="c-user-login">
    <Header />
    <UserLoginForm />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import UserLoginForm from './UserLoginForm'

export default {
  components: {
    Header,
    Footer,
    UserLoginForm
  },
  data () {
    return {
      username: null,
      password: null
    }
  },
  name: 'UserLogin'
}
</script>
