<template>
  <div class="c-user-login-form">
    <div class="el-login-main">
      <div class="el-login">
        <div class="login-icon">
          <i class="el-icon-user-solid"></i>
        </div>
        <el-form
          ref="form"
          v-model="state.userData"
          v-if="state.loginForm"
        >
          <el-form-item>
            <el-input
              placeholder="User Name"
              v-model="state.userData.username"
              class="input">
            </el-input>
            <span
              class="c-user-login-form__error"
              v-if="v$.userData.username.$error"
            >
              {{ v$.userData.username.$errors[0].$message }}
            </span>
          </el-form-item>

          <el-form-item>
            <el-input
              placeholder="Password"
              v-model="state.userData.password"
              type="password"
              class="input">
            </el-input>
            <span
              class="c-user-login-form__error"
              v-if="v$.userData.password.$error"
            >
              {{ v$.userData.password.$errors[0].$message }}
            </span>
          </el-form-item>

          <div class="c-user-login-form__forget-password">
            <a @click="toggleLoginForm">
              Forgot your password?
            </a>
          </div>

          <div class="button-login">
            <el-button
              @click="onSubmit"
              type="danger"
            >
              Login</el-button>
          </div>
        </el-form>

        <el-form
          ref="form"
          v-model="state.userData"
          v-else
        >
          <el-form-item>
            <el-input
              placeholder="User Name"
              v-model="state.userData.username"
              class="input">
            </el-input>
            <span
              class="c-user-login-form__error"
              v-if="v$.userData.username.$error"
            >
              {{ v$.userData.username.$errors[0].$message }}
            </span>
          </el-form-item>
          <div class="c-user-login-form__forget-password">
            <a @click="toggleLoginForm">
              Login
            </a>
          </div>

          <div class="button-login">
            <el-button
              @click="onForgetPassword"
              type="danger"
            >
              Submit</el-button>
          </div>
        </el-form>
        <div class="alternate-login">
          <div class="heading">
            or Login with
          </div>
          <a @click="onFacebookClick"><img src="@/assets/images/facebook.png" width="32" alt=""></a>
          <a @click="onGoogleClick"><img src="@/assets/images/google.png" width="32" alt=""></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixins/user'

import { computed, reactive } from 'vue'
import { email, required, helpers } from '@vuelidate/validators'
import useValidate from '@vuelidate/core'

export default {
  setup () {
    const state = reactive({
      userData: {
        username: '',
        password: ''
      },
      loginForm: true
    })

    const rules = computed(() => {
      return {
        userData: {
          username: { required: helpers.withMessage('Username field is required', required), email },
          password: { required: helpers.withMessage('Password field is required', required) }
        }
      }
    })

    const v$ = useValidate(rules, state)

    return {
      state,
      v$
    }
  },

  methods: {
    toggleLoginForm () {
      this.state.loginForm = !this.state.loginForm
    },

    onSubmit () {
      this.v$.$validate()
      if (!this.v$.$error) {
        this.loginUser(this.state.userData).then((res) => {
          if (res.user && res.user.position) {
            this.$notify({
              message: 'Unauthorized to login',
              type: 'warning'
            })

            localStorage.removeItem('admin')
            localStorage.removeItem('USER_TOKEN')
          } else {
            this.$message({
              message: 'User logged in.',
              type: 'success'
            })

            this.$router.push({ name: 'user-dashboard' })
          }
        }).catch((error) => {
          this.$message({
            message: error.response.data.error ? 'Username/Email and password does not match.' : error.response.data.message,
            type: 'warning',
            offset: 12
          })
        })
      }
    },

    onFacebookClick () {
      window.location.href = process.env.VUE_APP_API_BASE_URL + '/auth/facebook'
    },

    onGoogleClick () {
      window.location.href = process.env.VUE_APP_API_BASE_URL + '/auth/google'
    },

    onForgetPassword () {
      this.forgetPassword({
        email: this.state.userData.username
      }).then(() => {
        this.$message({
          message: 'Password reset link has been sent to you email. Please click on link to reset password.',
          type: 'success'
        })
      }).catch((error) => {
        this.$message({
          message: error.response.data.error ? 'Invalid Data' : error.response.data.message,
          type: 'warning'
        })
      })
    }
  },

  mixins: [userMixin],

  name: 'UserLoginForm'
}
</script>
<style lang="scss">
.c-user-login-form {
  &__error {
    color: darkred;
  }

  &__forget-password {
    text-align: center;
    margin: 1rem 0;
  }
}
.el-login-main{
  background: #f3f2ef;
  margin: 0;
  padding: 30px 0;
}

.el-login{
  margin: 0 auto;
  padding:30px 30px;
  width: 340px;
  background: #fff;
  border-radius: 10px;
}

.login-icon{
  background: #003895;
  text-align: center;
  border-radius: 50px;
  width: 80px;
  height: 80px;
  border: 1px solid #ccc;
  margin: 0 auto 5px;
  padding: 7px;
  position: relative;
  font-size: 52px;
  color: #fff;
}

.el-input__inner {
  background-color: #eee;
  border: none;
  padding: 15px 15px;
  margin: 8px 0;
  width: 100%;
}

.forgetpass{
  margin: 0;
  padding: 15px 0 15px 0;
  color: #000;
  font-size: 13px;
  text-align: center;
}

.button-login{
  text-align: center;
}

.el-form-item {
  margin-bottom: 0;
}
.alternate-login {
  margin: 0;
  padding: 15px 0 0 0;
  text-align: center;
}

.alternate-login img{
  margin: 10px 4px 0 4px;

}

.alternate-login .heading{
  margin: 0;
  padding: 15px 0 5px 0;
  text-align: center;
  font-style: italic;
}
</style>
